import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  private readonly apiUrl;

  constructor(private httpClient: HttpClient) { 
    this.apiUrl = environment.server;
  }

  get(path: string, params = {}, headers = {}) {
    return this.httpClient.get(`${this.apiUrl}${path}`, { params, headers });
  }

  put(path: string, body = {}, params = {}) {
    return this.httpClient.put(`${this.apiUrl}${path}`, body, { params });
  }

  post(path: string, body = {}, params = {}, headers = {}) {
    return this.httpClient.post(`${this.apiUrl}${path}`, body, { params, headers });
  }

  delete(path: string, params = {}, body = {}) {
    return this.httpClient.delete(`${this.apiUrl}${path}`, { params, body });
  }
}
